<template>
  <div
    class="playbook-list-item"
    :class="{ editable: canEdit }"
    @click.prevent="$emit('selected')"
  >
    <div class="left">
      <scope-icon :scope="playbook.scope"></scope-icon>
    </div>
    <div class="right">
      <div class="name">
        {{ playbook.displayName }}
      </div>
      {{ playbook.description }}
    </div>
  </div>
</template>

<script>
import ScopeIcon from "../../../../components/ScopeIcon.vue";
export default {
  props: ["playbook", "orgId"],
  components: { ScopeIcon },
  computed: {
    canEdit() {
      return this.playbook.ownerId === this.orgId;
    },
  },
};
</script>

<style scoped lang="scss">
.playbook-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    min-width: 35px;

    // min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  cursor: pointer;
  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .name {
    font-weight: 500;
    margin-bottom: 5px;
  }
}
</style>