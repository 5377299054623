<template>
  <div class="admin-playbooks">
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings - Playbooks
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search playbooks"
          v-model="searchQuery"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadPlaybooks"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
        v-if="showCreateButton"
          @click.prevent="createPlaybook"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- List wrapper -->
    <div class="playbooks-list-wrapper">
      <!-- <a-alert style="margin-bottom: 25px" message="Playbooks are TODO">
      </a-alert> -->

      <div class="playbook-org-context-switcher-wrapper">
        <playbook-org-context-switcher
          @set-owner-id-filter="setOwnerIdFilter"
          :owner-id-filter="ownerIdFilter"
          :playbooks="playbooks"
        ></playbook-org-context-switcher>
      </div>

      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No playbooks -->
      <a-alert
        v-if="!isLoading && playbooksToShow.length == 0"
        type="info"
        message="No playbooks to show"
      >
      </a-alert>
      <!-- / No playbooks -->

      <!-- Loaded -->
      <div class="playbooks-list" v-if="!isLoading && playbooksToShow.length">
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="playbook in playbooksToShow"
            :key="playbook.id"
          >
            <playbook-list-item
              :playbook="playbook"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToPlaybook(playbook)"
            ></playbook-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PlaybookListItem from "./Playbooks/PlaybookListItem.vue";
import playbooks from "../../../api/guidance/playbooks";
import PlaybookOrgContextSwitcher from "../../../components/Admin/Playbooks/PlaybookOrgContextSwitcher.vue";
const _ = require("lodash");
export default {
  components: { PlaybookListItem, PlaybookOrgContextSwitcher },

  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: false,
      playbooks: [],
      searchQuery: "",
      ownerIdFilter: null,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.ownerIdFilter = this.selectedOrganisation.id;
    this.loadPlaybooks();
  },

  watch: {
    selectedOrganisation() {
      this.loadPlaybooks();
    },
  },

  methods: {
    setOwnerIdFilter(val) {
      this.ownerIdFilter = val;
    },

    navigateToPlaybook(playbook) {
      this.$router.push("/admin/settings/playbooks/" + playbook.id);
    },

    createPlaybook() {
      this.$router.push("/admin/settings/playbooks/create");
    },

    loadPlaybooks() {
      let vm = this;
      vm.isLoading = true;
      vm.playbooks = [];
      playbooks
        .getPlaybooksForOrg(this.tenantId, this.selectedOrganisation.id, true)
        .then((r) => {
          vm.playbooks = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading playbooks");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    showCreateButton() {
      return (!this.ownerIdFilter || this.ownerIdFilter == this.selectedOrganisation.id)
    },

    playbooksToShow() {
      let filtered = _.filter(this.playbooks, (playbooks) => {
        if (this.searchQuery.trim().length) {
          return playbooks.displayName
            .toLowerCase()
            .includes(this.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
      let ordered = _.orderBy(filtered, "displayName");
      return _.filter(ordered, playbook => {
        return playbook.ownerId == this.ownerIdFilter;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.playbook-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.playbook-org-context-switcher-wrapper {
  margin-bottom: 25px;
}
</style>